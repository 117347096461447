<template>
  <div class="container">
    <edit-header
      entity=""
      :error.sync="error"
      :invalid.sync="invalid"
      :invalidMessage="invalidMessage"
      :submitted.sync="submitted"
      :isNew="isNew">
    </edit-header>
    <form
      v-disable-all="!can(uiPermissions.SUPPLIERS_TRADING_TERMS_UPDATE)"
      ref="form"
      @submit.prevent="submit">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <router-link
                :to="`/suppliers/${supplierId}?t=trading-terms`"
                class="btn btn-secondary"
                data-test="btn-back">
                <i class="fe fe-chevron-left"></i> Back
              </router-link>
              <h3 class="card-title text-center flex-grow-1 font-weight-semibold">National Trading Terms</h3>
              <div class="d-flex align-items-center">
                <button
                  v-if="item.status === 'draft' && !edit && can(uiPermissions.SUPPLIERS_TRADING_TERMS_UPDATE)"
                  type="button"
                  data-test="btn-edit"
                  class="btn btn-secondary"
                  :class="'pointer'"
                  @click="edit=true">
                  <i class="fe fe-edit"></i> Edit
                </button>
                <button
                  v-if="edit && !isNew"
                  type="button"
                  class="btn btn-secondary"
                  data-test="btn-cancel"
                  :class="'pointer'"
                  @click="cancelEdit">
                  <i class="fe fe-x"></i> Cancel
                </button>
                <button
                  v-if="isNew"
                  type="button"
                  data-test="btn-reset"
                  class="btn btn-secondary"
                  :class="'pointer'"
                  @click="reset">
                  Reset
                </button>
                <button
                  v-if="!edit"
                  type="button"
                  data-test="btn-print"
                  class="btn btn-secondary ml-2"
                  @click="printPage">
                  <i class="fe fe-printer"></i> Print
                </button>
              </div>
            </div>
            <div class="card-body">
              <dimmer :active="loading">
                <div
                  class="print-only justify-content-center align-items-center p-4 mb-6 font-weight-semibold bg-gray-lighter text-uppercase">
                  National Trading Terms
                </div>
                <div class="container">
                  <div class="row">
                    <div class="col col-flex">
                      <label class="font-weight-semibold"> Valid From: </label>
                      <div class="ml-2">
                        <input
                          v-if="edit"
                          v-model="item.valid_from"
                          type="date"
                          data-test="txt-valid-from"
                          required
                          class="form-control"/>
                        <span v-else data-test="text-valid-from">
                          {{item.valid_from && moment(item.valid_from).format('DD/MM/YYYY') || 'NA'}}
                        </span>
                      </div>
                    </div>
                    <div class="col col-flex">
                      <label class="font-weight-semibold"> Valid To: </label>
                      <div class="ml-2">
                        <input
                          v-if="edit"
                          v-model="item.valid_to"
                          type="date"
                          data-test="txt-valid-to"
                          required
                          class="form-control"/>
                        <span v-else data-test="text-valid-to">
                          {{item.valid_to && moment(item.valid_to).format('DD/MM/YYYY') || 'NA'}}
                        </span>
                      </div>
                    </div>
                    <div class="col col-flex">
                      <label class="font-weight-semibold"> Status: </label>
                      <div class="ml-2">
                        <select
                          v-if="edit && !isNew"
                          v-model="item.status"
                          data-test="txt-status"
                          class="form-control custom-select">
                          <option value="draft">Draft</option>
                          <option value="active">Active</option>
                          <option value="expired">Expired</option>
                        </select>
                        <span
                          v-else
                          class="text-capitalize"
                          :class="statusColor">
                          {{item.status || 'NA'}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="d-flex justify-content-center align-items-center p-2 my-4 bg-gray-lightest font-weight-lightbold">
                  Supplier Details
                </div>

                <div class="container">
                  <div class="row">
                    <div class="col">
                      <div class="row">
                        <div class="col">
                          <label class="font-weight-semibold"> Legal Name: </label>
                          <span class="ml-2">
                            {{supplier.legal_name || 'NA'}}
                          </span>
                        </div>

                        <div class="col">
                          <label class="font-weight-semibold"> Trading Name: </label>
                          <span class="ml-2">
                            {{supplier.name || 'NA'}}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <label class="font-weight-semibold"> License Number: </label>
                          <span class="ml-2">
                            {{supplier.trading_license_number || 'NA'}}
                          </span>
                        </div>
                        <div class="col">
                          <label class="font-weight-semibold"> VAT Registration Number: </label>
                          <span class="ml-2">
                            {{supplier.tax_registration_number || 'NA'}}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="d-flex justify-content-center align-items-center p-2 my-4 bg-gray-lightest font-weight-lightbold">
                  Payment Method & Settlement Discount
                </div>

                <div class="container">
                  <div class="row">
                    <div class="col">
                      <div class="row">
                        <div class="col">
                          <label class="font-weight-semibold"> Bank Account: </label>
                          <span class="ml-2">
                            {{supplier.bank_account || 'NA'}}
                          </span>
                        </div>

                        <div class="col">
                          <label class="font-weight-semibold"> Payment Terms (from SOA): </label>
                          <span class="ml-2">
                            {{supplier.payment_terms || 'NA'}}
                          </span>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="row">
                        <div class="col col-flex">
                          <label class="font-weight-semibold"> Settlement Discount: </label>
                          <div class="ml-2 d-flex align-items-center">
                            <input
                              v-if="edit"
                              v-model="item.settlement_discount"
                              type="number"
                              min="0"
                              step="any"
                              required
                              data-test="txt-settlement-discount"
                              class="form-control w-9"/>
                            <span v-else>
                              {{item.settlement_discount || 'NA'}}
                            </span>
                            <span v-if="edit || item.settlement_discount" :class="edit?'ml-1':''">%</span>
                          </div>
                        </div>

                        <div class="col d-flex">
                          <label class="font-weight-semibold"> Notes: </label>
                          <div class="ml-2 w-100">
                            <textarea
                              v-if="edit"
                              v-model="item.notes"
                              type="text"
                              data-test="txt-notes"
                              class="form-control"></textarea>
                            <span v-else>
                              {{item.notes || 'NA'}}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="d-flex justify-content-center align-items-center p-2 my-4 bg-gray-lightest font-weight-lightbold">
                  Trading Terms
                </div>

                <table class="table table-bordered" :class="edit? 'edit':''">
                  <thead>
                    <tr>
                      <th>KPI Description</th>
                      <th>Owner</th>
                      <th>Target</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(lineItem, index) in item.line_items"
                      :key="index"
                      :class="{'d-none': loading}">
                      <td>

                        <input
                          v-if="edit"
                          v-model="lineItem.kpi_description"
                          type="text"
                          required
                          class="form-control"/>
                        <span v-else class="text-capitalize">
                          {{lineItem.kpi_description || 'NA'}}
                        </span>

                      </td>
                      <td>
                        <select
                          v-if="edit"
                          v-model="lineItem.owner"
                          required
                          class="form-control custom-select">
                          <option
                            disabled
                            selected
                            value>
                            None
                          </option>
                          <option value="Supplier">Supplier</option>
                          <option value="Hello Chef">Hello Chef</option>
                        </select>
                        <span v-else>
                          {{lineItem.owner || 'NA'}}
                        </span>
                      </td>
                      <td>
                        <div class="d-flex align-items-center">
                          <input
                            v-if="edit"
                            v-model="lineItem.target"
                            type="number"
                            min="0"
                            step="any"
                            required
                            class="form-control"/>
                          <span v-else>
                            {{lineItem.target && lineItem.target + "%" || "NA"}}
                          </span>
                        </div>
                      </td>
                      <td v-if="edit" class="w-8">
                        <button
                          type="button"
                          data-test="button-remove-line-item"
                          class="btn btn-danger ml-2"
                          :class="'pointer'"
                          @click="removeLineItem(index)">
                          <i class="fe fe-x"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div v-if="edit" class="row">
                  <div class="col">
                    <button
                      type="button"
                      data-test="button-add-line-item"
                      class="btn btn-primary"
                      :class="'pointer'"
                      @click="addLineItem">
                      <i class="fe fe-plus"></i> Add
                    </button>
                  </div>
                </div>

                <div class="row mt-4 print-only">
                  <div class="col d-flex flex-column">
                    <label class="font-weight-semibold mb-2">
                      <u> Supplier Score Card </u>
                    </label>
                    <p>
                      The Supplier Score Card is published weekly/monthly and runs accumilative from January to
                      December.
                      The scorcard is discussed in a weekly/monthly meeting with Hello Chef Senior Buyer and a Senior
                      Representative from the Supplier.
                    </p>

                    <p>
                      The intention of the Supplier Score Card is to focus both parties on the Key Performance
                      Indicators
                      in the relationship: Strike rate On Time Delivery, Forecast Error Rate and Data Accuracy.
                    </p>

                    <p>
                      The intention is that we work together on solutions to rectify any items that are problematic, and
                      address them immediately.
                    </p>

                    <p>
                      Non-adherence to the KPI's that are highlighted in the scorecard meeting could result in contract
                      termination or penalties.
                    </p>
                  </div>
                </div>

                <div
                  class="d-flex justify-content-center align-items-center p-2 my-4 bg-gray-lightest font-weight-lightbold">
                  Base Rebate & Growth Rebate
                </div>

                <div class="container">
                  <div class="row">
                    <div class="col col-flex">
                      <label class="font-weight-semibold"> Base Rebate: </label>
                      <div class="ml-2 d-flex align-items-center">
                        <input
                          v-if="edit"
                          v-model="item.base_rebate"
                          type="number"
                          min="0"
                          step="any"
                          required
                          data-test="txt-base-rebate"
                          class="form-control w-9"/>
                        <span v-else>
                          {{item.base_rebate || 'NA'}}
                        </span>
                        <span v-if="edit || item.base_rebate" :class="edit?'ml-1':''">%</span>
                      </div>
                    </div>

                    <div class="col col-flex">
                      <label class="font-weight-semibold"> Growth Hurdles Measurement: </label>
                      <div class="ml-2">
                        <select
                          v-if="edit"
                          v-model="item.growth_hurdle_measurement"
                          required
                          data-test="txt-growth-hurdles-measurement"
                          class="form-control custom-select">
                          <option
                            disabled
                            selected
                            value>
                            None
                          </option>
                          <option value="value">Value</option>
                          <option value="volume">Volume</option>
                        </select>
                        <span v-else class="text-capitalize">
                          {{item.growth_hurdle_measurement || 'NA'}}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <div
                        v-for="(rebate,index) in item.rebates"
                        :key="index"
                        class="row"
                        :class="{'d-none':loading}">
                        <div class="col col-flex">
                          <label class="font-weight-semibold"> Negotiated Growth Incentive: </label>
                          <div class="ml-2 d-inline-flex">
                            <input
                              v-if="edit"
                              v-model="rebate.description"
                              type="text"
                              required
                              class="form-control"/>
                            <span v-else>
                              {{rebate.description || 'NA'}}
                            </span>
                          </div>

                          <div class="mx-8">
                            <label class="font-weight-semibold text-capitalize"> If </label>

                            <div class="mx-2 d-inline-flex align-items-center w-9">
                              <input
                                v-if="edit"
                                v-model="rebate.target"
                                type="number"
                                step="any"
                                required
                                class="form-control"/>
                              <span v-else>
                                {{rebate.target || 'NA'}}
                              </span>
                              <span v-if="edit || rebate.target" :class="edit?'ml-1':''">%</span>
                            </div>

                            <label class="mx-2 font-weight-semibold">
                              Achieved, add
                            </label>

                            <div class="mx-2 d-inline-flex align-items-center w-9">
                              <input
                                v-if="edit"
                                v-model="rebate.rebate_value"
                                type="number"
                                step="any"
                                required
                                class="form-control"/>
                              <span v-else>
                                {{rebate.rebate_value || 'NA'}}
                              </span>
                              <span v-if="edit || rebate.rebate_value" :class="edit?'ml-1':''">%</span>
                            </div>

                          </div>

                          <label class="mr-2 font-weight-semibold text-capitalize"> For Purchases </label>

                          <button
                            v-if="edit"
                            type="button"
                            class="btn btn-danger ml-4"
                            :class="'pointer'"
                            @click="removeRebate(index)">
                            <i class="fe fe-x"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="edit" class="row">
                    <div class="col">
                      <button
                        type="button"
                        data-test="button-add-rebate"
                        class="btn btn-primary"
                        :class="'pointer'"
                        @click="addRebates">
                        <i class="fe fe-plus"></i> Add
                      </button>
                    </div>
                  </div>

                  <div class="row mt-4 print-only">
                    <div class="col">
                      <p>
                        Growth Incentive percentage is in addition to Base Incentive. If Growth Hurdle is not paid by
                        end
                        January we will deduct. In calculating whether growth is payable we will take into account any
                        short delivery below a 95% strike rate. The strike rate percentage below 95% will be added to
                        the
                        account growth to determine growth achieved.The calculation will be made up of Hello Chef total
                        Goods receipts, Less returns Notes (Net Hello Chef Goods Receipts) Indicate if Monthly or
                        Quarterly deductible, or to be Indicate Payment method.
                      </p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col col-flex">
                      <label class="font-weight-semibold"> Deduct Bank Transfer: </label>
                      <div class="ml-2">
                        <select
                          v-if="edit"
                          v-model="item.payment_method"
                          required
                          data-test="txt-deduct-bank-transfer"
                          class="form-control custom-select">
                          <option
                            disabled
                            selected
                            value>
                            None
                          </option>
                          <option value="annually">Annually</option>
                          <option value="quarterly">Quarterly</option>
                          <option value="bimonthly">Bimonthly</option>
                        </select>
                        <span v-else class="text-capitalize">
                          {{item.payment_method || 'NA'}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="d-flex justify-content-center align-items-center p-2 my-4 bg-gray-lightest font-weight-lightbold">
                  Trading Term Signature
                </div>

                <div class="container auto-width">
                  <div class="row">
                    <div class="col">
                      <div class="row border-bottom">
                        <div class="col px-4 py-2 border-right">
                          <div class="row">
                            <div class="col col-flex">
                              <label class="font-weight-semibold"> Supplier: </label>
                              <div class="ml-2">
                                <input
                                  v-if="edit"
                                  v-model="item.supplier_representative_name"
                                  type="text"
                                  required
                                  data-test="txt-supplier"
                                  class="form-control"/>
                                <span v-else>
                                  {{item.supplier_representative_name || 'NA'}}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div class="row print-only">
                            <div class="col d-flex">
                              <label class="font-weight-semibold"> Signature: </label>
                              <label class="ml-2 h-8"></label>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col col-flex ">
                              <label class="font-weight-semibold"> Date Signed: </label>
                              <div class="ml-2">
                                <input
                                  v-if="edit"
                                  v-model="item.supplier_sign_date"
                                  type="date"
                                  required
                                  data-test="txt-supplier-date-signed"
                                  class="form-control"/>
                                <span v-else>
                                  {{item.supplier_sign_date && moment(item.supplier_sign_date).format('DD/MM/YYYY') ||
                                    'NA'}}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col px-4 py-2">
                          <div class="row">
                            <div class="col col-flex">
                              <label class="font-weight-semibold"> Hello Chef <br/> Senior Representative: </label>
                              <div class="ml-2">
                                <input
                                  v-if="edit"
                                  v-model="item.representative_name"
                                  type="text"
                                  required
                                  data-test="txt-hc-senior-rep"
                                  class="form-control"/>
                                <span v-else>
                                  {{item.representative_name || 'NA'}}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div class="row print-only">
                            <div class="col d-flex">
                              <label class="font-weight-semibold"> Signature: </label>
                              <label class="ml-2 h-8"></label>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col col-flex">
                              <label class="font-weight-semibold"> Date Signed: </label>
                              <div class="ml-2">
                                <input
                                  v-if="edit"
                                  v-model="item.representative_sign_date"
                                  type="date"
                                  required
                                  data-test="txt-hc-senior-rep-date-signed"
                                  class="form-control"/>
                                <span v-else>
                                  {{item.representative_sign_date &&
                                    moment(item.representative_sign_date).format('DD/MM/YYYY') || 'NA'}}
                                </span>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-6 px-4 py-2">
                          <div class="row">
                            <div class="col col-flex">
                              <label class="font-weight-semibold"> Senior Buyer: </label>
                              <div class="ml-2">
                                <input
                                  v-if="edit"
                                  v-model="item.buyer_name"
                                  type="text"
                                  required
                                  data-test="txt-senior-buyer"
                                  class="form-control"/>
                                <span v-else>
                                  {{item.buyer_name || 'NA'}}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div class="row print-only">
                            <div class="col d-flex">
                              <label class="font-weight-semibold"> Signature: </label>
                              <label class="ml-2 h-8"></label>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col col-flex">
                              <label class="font-weight-semibold"> Date Signed: </label>
                              <div class="ml-2">
                                <input
                                  v-if="edit"
                                  v-model="item.buyer_sign_date"
                                  type="date"
                                  required
                                  data-test="txt-senior-buyer-date-signed"
                                  class="form-control"/>
                                <span v-else>
                                  {{item.buyer_sign_date && moment(item.buyer_sign_date).format('DD/MM/YYYY') || 'NA'}}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="edit || dirty" class="row mt-4">
                    <div class="col">
                      <div class="row">
                        <edit-footer
                          :dirty="dirty"
                          entity="term"
                          :isNew="isNew"
                          :submitting="submitting"
                          :isValid="!dirty"
                          :hide-delete="true">
                        </edit-footer>
                      </div>
                    </div>
                  </div>
                </div>
              </dimmer>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import EditFooter from '@/components/EditFooter';
import EditHeader from '@/components/EditHeader';
import edit from '@/mixins/edit';
import {suppliers, tradingTerms} from '@/services';
import loadsRelations from '@hellochef/shared-js/mixins/loadsRelations';
import moment from 'moment';

export default {
  components: {EditFooter, EditHeader},
  mixins: [loadsRelations, edit],
  data() {
    return {
      edit: this.isNew,
      loading: false,
      supplierId: this.$route.params.supplierId,
      with: ['lineItems', 'rebates'],
      supplier: {},
      term: {},
      item: {
        status: this.isNew ? 'draft' : '',
        settlement_discount: '',
        base_rebate: '',
        growth_hurdle_measurement: '',
        payment_method: '',
        notes: '',
        supplier_representative_name: '',
        supplier_sign_date: '',
        buyer_name: '',
        buyer_sign_date: '',
        representative_name: '',
        representative_sign_date: '',
        valid_from: '',
        valid_to: '',
        line_items: [
          {
            kpi_description: 'Strike rate',
            owner: 'Supplier',
            target: '99.5',
          },
          {
            kpi_description: 'On time delivery',
            owner: 'Supplier',
            target: '99.5',
          },
          {
            kpi_description: 'Article data accuracy',
            owner: 'Supplier',
            target: '100.0',
          },
          {
            kpi_description: 'Forecast error rate',
            owner: 'Hello Chef',
            target: '15.0',
          }, {
            kpi_description: 'On time payment',
            owner: 'Hello Chef',
            target: '100.0',
          },
        ],
        rebates: [
          {
            description: 'Hurdle 1',
            target: '10',
            rebate_value: '1',
          },
          {
            description: 'Hurdle 2',
            target: '15',
            rebate_value: '1.50',
          },
          {
            description: 'Hurdle 3',
            target: '20',
            rebate_value: '2.25',
          },
          {
            description: 'Hurdle 4',
            target: '25',
            rebate_value: '3.25',
          },
          {
            description: 'Hurdle 5',
            target: '30',
            rebate_value: '4.50',
          },
        ],
      },
    };
  },
  computed: {
    statusColor() {
      return this.item.status === 'active' ? 'text-success' : this.item.status === 'draft' ? 'text-secondary' : this.item.status === 'expired' ? 'text-danger' : '';
    },
  },
  async created() {
    this.loading = true;
    try {
      const {item: supplier} = await suppliers.getById(this.supplierId);
      this.supplier = supplier;
      if (this.isNew) {
        this.item.settlement_discount = supplier.settlement_discount;
      }
    }
    finally {
      this.loading = false;
    }
  },
  methods: {
    fetchData(id) {
      return tradingTerms
        .path(`suppliers/${this.supplierId}/trading-terms`)
        .getById(id, {params: {...this.withRelations()}});
    },
    addLineItem() {
      this.item.line_items.push({
        kpi_description: '',
        owner: '',
        target: '',
        trading_term_id: this.item.id,
      });
    },
    removeLineItem(index) {
      this.item.line_items.splice(index, 1);
    },
    addRebates() {
      this.item.rebates.push({
        description: '',
        target: '',
        rebate_value: '',
        trading_term_id: this.item.id,
      });
    },
    removeRebate(index) {
      this.item.rebates.splice(index, 1);
    },
    cancelEdit() {
      // reset the data back to orignal if the user cancel edit
      this.reset();
      this.edit = false;
    },
    filterEdits() {
      this.item.line_items = this.item.line_items.filter(f => f.kpi_description && f.owner && f.target);
      this.item.rebates = this.item.rebates.filter(f => f.description && f.rebate_value && f.target);
    },
    async validate() {
      const validDate = moment(this.item.valid_from).isBefore(moment(this.item.valid_to));
      if (!validDate) {
        this.invalidMessage = 'Valid from can not be greater than Valid to.';
        return false;
      }
      return true;
    },
    submitData(item) {
      this.filterEdits();
      return tradingTerms
        .path(`suppliers/${this.supplierId}/trading-terms`)
        .saveOrUpdate(item, {params: {...this.withRelations()}});
    },
    completed() {
      if (!this.submitted) return;
      this.edit = false;
      if (this.isNew) {
        this.$router.push(`/suppliers/${this.supplierId}/trading-terms/${this.item.id}`);
      }
    },
    printPage() {
      window.print();
    },
  },
};
</script>

<style scoped>
label {
  margin-bottom: 0;
}

.container .row+.row {
  margin-top: .5rem;
}

.container .row .col+.col {
  margin-left: .5rem;
}

.col-flex {
  display: flex;
  align-items: center;
}

.btn:disabled {
  cursor: not-allowed;
}

.table th {
  color: inherit;
  font-weight: 700;
}

.table.edit td {
  padding: 0.25rem;
}

.auto-width label {
  min-width: 200px;
}
</style>
